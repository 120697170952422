import { actionCreatorFactory } from "typescript-fsa";
import { asyncFactory } from "typescript-fsa-redux-thunk";
import { unparse as Papa } from "papaparse";
import { sortBy } from "lodash";
import { AppState } from "store/types";
import {
  CVSRowExportType,
  InviteTeamMemberRequest,
  PostTagsProjectUploadTagsRequest,
  ChangeProjectMemberAccessRequest,
  SafeArea,
  TagRow,
  TagKeys,
  CSVHeaders,
  CSVExportRequest,
  KeysByPhase,
  KeysByIssue,
  CSVOneFileExportRequest,
} from "./types";
import fileSaver from "file-saver";
import {
  ApiV1ProjectsIdAreasTechnicianPostRequest,
  ApiV1ProjectsIdKpiGetRequest,
  ApiV1ProjectsIdPhasesPostRequest,
  ApiV1ProjectsIdPutRequest,
  ApiV1ProjectsIdTagsUploadPostRequest,
  ApiV1ProjectsPostRequest,
  ApiV1TagsIdTechnicianPostRequest,
  ApiV1ProjectsIdChecklistsPostRequest,
  Area,
  Checklist,
  Phase,
  PhaseControllerResponse,
  PhaseCompletionStatus,
  ProjectListControllerResponse,
  ProjectsApi,
  Tag,
  TagCountsByAreaAndPhaseListControllerResponse,
  TagsApi,
  UsersApi,
  ApiV1ProjectsIdUsersPutRequest,
  User,
  ApiV1ProjectsIdPhasesGetRequest,
  Project,
  ApiV1ProjectsIdIssuesGetRequest,
  TagIssue,
  AssetType,
  ApiV1ProjectsIdIssuesIssueIdPutRequest,
  ApiV1ProjectsIdTagsTagIdGetRequest,
  TagCompletionDetail,
  ApiV1ProjectsIdChecklistsChecklistIdPutRequest,
  ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest,
  ApiV1ProjectsIdCopyChecklistPostRequest,
  ApiV1ProjectsIdPhasePutRequest,
  ApiV1ProjectsIdRejectChecklistPostRequest,
  ApiV1ProjectsIdExclusionListPutRequest,
  ExclusionList,
  ApiV1ProjectsIdTagsPutRequest,
  ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest,
  ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest,
  ApiV1ProjectsIdGetFlexFieldsGetRequest,
  FlexFieldIEnumerableControllerResponse,
  ApiV1ProjectsAreaAttachmentsGetRequest,
  ApiV1ProjectsIdAreasAttachmentPostRequest,
  ApiV1ProjectsAreaAttachmentsDeleteRequest,
  ApiV1ProjectsIdAttachmentPostRequest,
  ApiV1ProjectsAttachmentsDeleteRequest,
} from "data/api";
import { call } from "data/api/rest";
import { formatArea, formatPhaseCompletion, formatTagIssue } from "./formats";
import { textTransform } from "utils/textTransform";
import { ChecklistToCopy } from "data/api/models/ChecklistToCopy";
import { dateFormat } from "utils/dateFormat";
import { CustomError } from "utils/customError";
import XLSX from "xlsx";

const createAction = actionCreatorFactory("PROJECTS");
const createAsyncAction = asyncFactory<AppState>(createAction);

export const getProjects = createAsyncAction<void, ProjectListControllerResponse, Error>("GET_PROJECTS", () => {
  return call(UsersApi).apiV1UsersProjectsGet();
});

export const getProjectById = createAction<number>("GET_PROJECT_BY_ID");
export const resetError = createAction<void>("RESET_ERROR");

export const getProjectAreas = createAsyncAction<number, Area[], Error>("GET_PROJECT_AREAS", async (projectId) => {
  const { data, error } = await call(ProjectsApi).apiV1ProjectsIdAreasGet({ id: projectId });
  if (!data) throw new Error(error?.message || "");
  return data || [];
});

export const getProjectAttachments = createAsyncAction<number, Area[], Error>(
  "GET_PROJECT_ATTACHMENTS",
  async (projectId) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdAttachmentsGet({ id: projectId });
    if (!data) throw new Error(error?.message || "");
    return data || [];
  }
);

export const postProjectAttachments = createAsyncAction<ApiV1ProjectsIdAttachmentPostRequest, Area[], Error>(
  "POST_PROJECT_ATTACHMENTS",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsAttachmentsPost({
      projectId: request.projectId,
      file: request.file,
    });
    if (!data) throw new Error(error?.message || "");
    return data || [];
  }
);

export const getProjectAreaAttachments = createAsyncAction<ApiV1ProjectsAreaAttachmentsGetRequest, Area[], Error>(
  "GET_PROJECT_AREA_ATTACHMENTS",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsAreaAttachmentsGet({
      projectId: request.projectId,
      areaId: request.areaId,
    });
    if (!data) throw new Error(error?.message || "");
    return data || [];
  }
);

export const postProjectAreaAttachments = createAsyncAction<ApiV1ProjectsIdAreasAttachmentPostRequest, Area[], Error>(
  "POST_PROJECT_AREA_ATTACHMENTS",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsAreaAttachmentsPost({
      projectId: request.projectId,
      areaId: request.areaId,
      file: request.file,
    });
    if (!data) throw new Error(error?.message || "");
    return data || [];
  }
);

export const getProjectPhases = createAsyncAction<ApiV1ProjectsIdPhasesGetRequest, Phase[], Error>(
  "GET_PROJECT_PHASES",
  async (request) => {
    const { data: phases, error } = await call(ProjectsApi).apiV1ProjectsIdPhasesGet(request);
    if (!phases) throw new Error(error?.message || "");

    //order checklist items before saving it to store
    phases.forEach((phase) =>
      (phase.checklists || []).forEach((checklist) =>
        (checklist.sections || []).forEach((section) => {
          section.items = sortBy(section.items || [], "order");
        })
      )
    );

    return phases;
  }
);

export const getProjectIssues = createAsyncAction<ApiV1ProjectsIdIssuesGetRequest, TagIssue[], Error>(
  "GET_PROJECT_ISSUES",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdIssuesGet(request);
    if (!data) throw new Error(error?.message || "");
    return data || [];
  }
);

export const editProjectMember = createAsyncAction<ApiV1ProjectsIdUsersPutRequest, { id: number; user: User }, Error>(
  "EDIT_PROJECT_MEMBER",
  async (requestParams) => {
    const { data } = await call(ProjectsApi).apiV1ProjectsIdUsersPut(requestParams);
    return { id: requestParams.id, user: data! } || { id: requestParams.id, user: {} };
  }
);

export const changeProjectMemberAccess = createAsyncAction<
  ChangeProjectMemberAccessRequest,
  ChangeProjectMemberAccessRequest,
  Error
>("REMOVE_PROJECT_MEMBER", async ({ projectId, userId, archive }) => {
  await call(ProjectsApi).apiV1ProjectsIdUsersArchivedPost({
    id: Number(projectId),
    archiveProjectUserRequest: { projectId: Number(projectId), userId: userId, archive: archive },
  });
  return { projectId, userId, archive };
});

export const uploadTagSheetCSV = createAsyncAction<PostTagsProjectUploadTagsRequest, AssetType[], Error>(
  "UPLOAD_TAG_SHEET_CSV",
  async ({ projectId, uploadTags }) => {
    const requestParameters: ApiV1ProjectsIdTagsUploadPostRequest = {
      id: projectId,
      uploadTag: uploadTags,
    };

    const { data: assetTypes } = await call(ProjectsApi).apiV1ProjectsIdTagsUploadPost(requestParameters);
    return assetTypes || [];
  }
);

export const inviteTeamMember = createAsyncAction<InviteTeamMemberRequest, User, Error>(
  "ADD_TEAM_MEMBER",
  async ({ id, ...inviteUserToProjectRequest }) => {
    const { data: user } = await call(ProjectsApi).apiV1ProjectsIdUsersPost({ inviteUserToProjectRequest, id });
    return user || {};
  }
);

export const exportTagSheetCSV = createAsyncAction<CSVExportRequest, void, Error>(
  "EXPORT_TAG_SHEET_TO_CSV",
  (request) => {
    const { phases, tags } = request;
    const jsonToPapa = (json: CVSRowExportType[]) => {
      if (json.length === 0) return [];
      const basicHeader = Object.keys(json[0].fixedColumns).map((key) => textTransform(key, TagKeys, CSVHeaders));
      const phasesNamesHeader = phases
        .map((phase) => [phase.name, KeysByPhase.Tech, KeysByPhase.Date, KeysByPhase.Time])
        .flat();
      const tagWithMaxNumberOfIssues = tags.reduce((prev, curr) => {
        if (!curr.issues || !prev.issues) return prev;
        if (curr.issues.length > prev.issues.length) {
          return curr;
        }
        return prev;
      });
      const issuesNamesHeader = !!tagWithMaxNumberOfIssues.issues
        ? tagWithMaxNumberOfIssues.issues
            .map((issue) => [
              KeysByIssue.IssueOpened,
              KeysByIssue.createdByUser,
              KeysByIssue.IssueType,
              KeysByIssue.Description,
              KeysByIssue.CriticalIssue,
              KeysByIssue.Resolved,
              KeysByIssue.resolvedAt,
              KeysByIssue.resolvedByUser
            ])
            .flat()
        : [];
      const flexFieldsHeaders = Object.keys(JSON.parse(json[0].flexFields));
      const csvHeaders = [...phasesNamesHeader, ...basicHeader, ...flexFieldsHeaders, ...issuesNamesHeader];
      const csvRows = json.map((row) => {
        const infoByPhase = phases.map((phase) => {
          const { phaseCompletionStatus, completedByUser, dateCompleted } =
            row.phaseCompletions.find((phaseCompletion) => phase.id && phaseCompletion.phaseId === phase.id) || {};
          return [
            phaseCompletionStatus || PhaseCompletionStatus.NotStarted,
            completedByUser || "",
            dateCompleted ? dateFormat(dateCompleted, "short") : "",
            dateCompleted ? dateFormat(dateCompleted, "time") : "",
          ];
        });

        const infoByIssue = row.tagIssues.map((issue) => {
          const { createdAt, createdByUser,issueType, description, isCritical, isResolved,resolvedByUser, resolvedAt } = issue;

          return [
            createdAt ? dateFormat(createdAt, "short") : "",
            createdByUser 
                ? `${createdByUser.firstName} ${createdByUser.lastName}` 
                : "",
            issueType || "",
            description || "",
            isCritical ? "yes" : "no",
            isResolved ? "yes" : "no",
            resolvedAt && resolvedAt.getFullYear() !== 1 ? dateFormat(resolvedAt, "short") : "",
            resolvedByUser 
                ? `${resolvedByUser.firstName} ${resolvedByUser.lastName}` 
                : "",
           ];
        });

        return [
          ...infoByPhase.flat(),
          ...Object.values(row.fixedColumns),
          ...Object.values(JSON.parse(row.flexFields)),
          ...infoByIssue.flat(),
        ];
      });
      return [csvHeaders, ...csvRows];
    };

    const formattedRows = request.tags.map((row: Tag) => ({
      phaseCompletions: row.phaseCompletions?.map(formatPhaseCompletion) || [],
      fixedColumns: {
        tagId: row.name || "",
        area: row.area?.name || "",
        assetType: row.asset?.assetType?.name || "",
        manufacturer: row.asset?.manufacturer || "",
        model: row.asset?.model || "",
        serialNumber: row.asset?.serialNumber || "",
      },
      tagIssues: row.issues?.map(formatTagIssue) || [],
      flexFields: row.flexFields || "{}",
    }));

    const papaCsvContent = jsonToPapa(formattedRows);
    //CSV
    // const rowsCsvFile = new File([Papa(papaCsvContent, { header: false })], "export.csv", {
    //   type: "text/csv",
    // });
    // fileSaver.saveAs(rowsCsvFile, `${request.projectName} Tag List.csv`);

    //XLSX
    const worksheet = XLSX.utils.aoa_to_sheet(papaCsvContent);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Tag List');
    XLSX.writeFile(workbook, `${request.projectName} Tag List.xlsx`);
  }
);

export const exportIssuesList = createAsyncAction<CSVExportRequest, void, Error>(
  "EXPORT_ISSUES_LIST",
  (request) => {
    const { phases, tags } = request;
    const jsonToPapa = (json: CVSRowExportType[]) => {
      if (json.length === 0) return [];
      const basicHeader = Object.keys(json[0].fixedColumns).map((key) => textTransform(key, TagKeys, CSVHeaders));
      const phasesNamesHeader = phases
        .map((phase) => [phase.name, KeysByPhase.Tech, KeysByPhase.Date, KeysByPhase.Time])
        .flat();
      const tagWithMaxNumberOfIssues = tags.reduce((prev, curr) => {
        if (!curr.issues || !prev.issues) return prev;
        if (curr.issues.length > prev.issues.length) {
          return curr;
        }
        return prev;
      });
      const issuesNamesHeader = !!tagWithMaxNumberOfIssues.issues
        ? tagWithMaxNumberOfIssues.issues
            .map((issue) => [
              KeysByIssue.IssueOpened,
              KeysByIssue.createdByUser,
              KeysByIssue.IssueType,
              KeysByIssue.Description,
              KeysByIssue.CriticalIssue,
              KeysByIssue.Resolved,
              KeysByIssue.resolvedAt,
              KeysByIssue.resolvedByUser
            ])
            .flat()
        : [];
      const flexFieldsHeaders = Object.keys(JSON.parse(json[0].flexFields));
      const csvHeaders = [...phasesNamesHeader, ...basicHeader, ...flexFieldsHeaders, ...issuesNamesHeader];
      const csvRows = json.map((row) => {
        const infoByPhase = phases.map((phase) => {
          const { phaseCompletionStatus, completedByUser, dateCompleted } =
            row.phaseCompletions.find((phaseCompletion) => phase.id && phaseCompletion.phaseId === phase.id) || {};
          return [
            phaseCompletionStatus || PhaseCompletionStatus.NotStarted,
            completedByUser || "",
            dateCompleted ? dateFormat(dateCompleted, "short") : "",
            dateCompleted ? dateFormat(dateCompleted, "time") : "",
          ];
        });

          const infoByIssue = row.tagIssues.map((issue) => {
          const { createdAt, createdByUser,issueType, description, isCritical, isResolved,resolvedByUser, resolvedAt } = issue;

          return [
            createdAt ? dateFormat(createdAt, "short") : "",
            createdByUser 
                ? `${createdByUser.firstName} ${createdByUser.lastName}` 
                : "",
            issueType || "",
            description || "",
            isCritical ? "yes" : "no",
            isResolved ? "yes" : "no",
            resolvedAt && resolvedAt.getFullYear() !== 1 ? dateFormat(resolvedAt, "short") : "",
            resolvedByUser 
                ? `${resolvedByUser.firstName} ${resolvedByUser.lastName}` 
                : "",
           
        ];
        });

        return [
          ...infoByPhase.flat(),
          ...Object.values(row.fixedColumns),
          ...Object.values(JSON.parse(row.flexFields)),
          ...infoByIssue.flat(),
        ];
      });
      return [csvHeaders, ...csvRows];
    };

    //**Filtering Step:** Only include tags with at least one issue having 'issueType' or 'createdAt'
    console.log("pre-filter",request.tags)
    const filteredTags = request.tags.filter(
      (tag) => tag.issues?.some((issue) => issue.issueType || issue.createdAt) ?? false
    );
    console.log("post-filter",filteredTags)

    const formattedRows = filteredTags.map((row: Tag) => ({
      phaseCompletions: row.phaseCompletions?.map(formatPhaseCompletion) || [],
      fixedColumns: {
        tagId: row.name || "",
        area: row.area?.name || "",
        assetType: row.asset?.assetType?.name || "",
        manufacturer: row.asset?.manufacturer || "",
        model: row.asset?.model || "",
        serialNumber: row.asset?.serialNumber || "",
      },
      tagIssues: row.issues?.map(formatTagIssue) || [],
      flexFields: row.flexFields || "{}",
    }));

    console.log("formattedRows",formattedRows)
    const papaCsvContent = jsonToPapa(formattedRows);
    //CSV
    // const rowsCsvFile = new File([Papa(papaCsvContent, { header: false })], "export.csv", {
    //   type: "text/csv",
    // });
    // fileSaver.saveAs(rowsCsvFile, `${request.projectName} Tag List.csv`);

    //XLSX
    const worksheet = XLSX.utils.aoa_to_sheet(papaCsvContent);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Issues');
    XLSX.writeFile(workbook, `${request.projectName} Issues.xlsx`);
  }
);


export const exportMultipleTagSheetsXLSX = createAsyncAction<CSVOneFileExportRequest, void, Error>(
  "EXPORT_MULTIPLE_TAG_SHEETS_TO_XLSX",
  (request) => {
    const workbook = XLSX.utils.book_new();
    const { phases, tags } = request;
    const jsonToPapa = (json: CVSRowExportType[], index: number) => {
      if (json.length === 0) return [];
      const basicHeader = Object.keys(json[0].fixedColumns).map((key) => textTransform(key, TagKeys, CSVHeaders));
      const phasesNamesHeader = phases
        .map((phase) => [phase[index]?.name, KeysByPhase.Tech, KeysByPhase.Date, KeysByPhase.Time])
        .flat();
      const tagWithMaxNumberOfIssues = tags.reduce((prev, curr) => {
        if (!curr[index]?.issues || !prev[index]?.issues) return prev;
        if (curr[index]?.issues!.length > prev[index]?.issues!.length) {
          return curr;
        }
        return prev;
      });
      const issuesNamesHeader = !!tagWithMaxNumberOfIssues[index]?.issues
        ? tagWithMaxNumberOfIssues[index]
            ?.issues!.map((issue) => [
              KeysByIssue.IssueOpened,
              KeysByIssue.createdByUser,
              KeysByIssue.IssueType,
              KeysByIssue.Description,
              KeysByIssue.CriticalIssue,
              KeysByIssue.Resolved,
              KeysByIssue.resolvedAt,
              KeysByIssue.resolvedByUser
            ])
            .flat()
        : [];
      const flexFieldsHeaders = Object.keys(JSON.parse(json[0].flexFields));
      const csvHeaders = [...phasesNamesHeader, ...basicHeader, ...flexFieldsHeaders, ...issuesNamesHeader];
      const csvRows = json.map((row) => {
        const infoByPhase = phases.map((phase) => {
          const { phaseCompletionStatus, completedByUser, dateCompleted } =
            row.phaseCompletions.find(
              (phaseCompletion) => phase[index]?.id && phaseCompletion.phaseId === phase[index]?.id
            ) || {};
          return [
            phaseCompletionStatus || PhaseCompletionStatus.NotStarted,
            completedByUser || "",
            dateCompleted ? dateFormat(dateCompleted, "short") : "",
            dateCompleted ? dateFormat(dateCompleted, "time") : "",
          ];
        });

        const infoByIssue = row.tagIssues.map((issue) => {
          const { createdAt, createdByUser,issueType, description, isCritical, isResolved,resolvedByUser, resolvedAt } = issue;

          return [
            createdAt ? dateFormat(createdAt, "short") : "",
            createdByUser 
                ? `${createdByUser.firstName} ${createdByUser.lastName}` 
                : "",
            issueType || "",
            description || "",
            isCritical ? "yes" : "no",
            isResolved ? "yes" : "no",
            resolvedAt && resolvedAt.getFullYear() !== 1 ? dateFormat(resolvedAt, "short") : "",
            resolvedByUser 
                ? `${resolvedByUser.firstName} ${resolvedByUser.lastName}` 
                : "",
          ];
        });

        return [
          ...infoByPhase.flat(),
          ...Object.values(row.fixedColumns),
          ...Object.values(JSON.parse(row.flexFields)),
          ...infoByIssue.flat(),
        ];
      });
      return [csvHeaders, ...csvRows];
    };

    request.tags.forEach((tag, index) => {
      const formattedRows = tag.map((row: Tag) => ({
        phaseCompletions: row.phaseCompletions?.map(formatPhaseCompletion) || [],
        fixedColumns: {
          tagId: row.name || "",
          area: row.area?.name || "",
          assetType: row.asset?.assetType?.name || "",
          manufacturer: row.asset?.manufacturer || "",
          model: row.asset?.model || "",
          serialNumber: row.asset?.serialNumber || "",
        },
        tagIssues: row.issues?.map(formatTagIssue) || [],
        flexFields: row.flexFields || "{}",
      }));
      const papaCsvContent = jsonToPapa(formattedRows, index);
      const worksheet = XLSX.utils.json_to_sheet(papaCsvContent);
      // console.log(request.worksheetNames[index], "\n", request.worksheetNames[index]?.replace(/[:\\\/\[\]\?\*]/g, "_").substring(0, 31))
      XLSX.utils.book_append_sheet(
        workbook,
        worksheet,
        request.worksheetNames[index]?.replace(/[:\\\/\[\]\?\*]/g, "_").substring(0, 31)
      );
    });

    XLSX.writeFile(workbook, request.projectName + ".xlsx");
  }
);

export const downloadTagSheetCSVTemplate = createAsyncAction<CSVExportRequest, void, Error>(
  "DOWNLOAD_TAG_SHEET_CSV_TEMPLATE",
  () => {
    const csvFile = Papa([
      {
        "Tag ID": null,
        Area: null,
        "Asset Type": null,
        Manufacturer: null,
        Model: null,
        "Serial Number": null,
      },
    ]);

    const rowsCsvFile = new File([csvFile], "export.csv", {
      type: "text/csv",
    });
    fileSaver.saveAs(rowsCsvFile);
  }
);

export const verifyTagSheetCSV = createAsyncAction<TagRow[], Error>("VERIFY_TAG_SHEET_CSV", (tagRows) => {
  return new Promise((resolve, reject) => {
    if (!tagRows.length) {
      reject();
    }
    const firstRow = tagRows[0];
    const missingKeys: string[] = [];

    Object.values(TagKeys).forEach((key) => {
      if (!firstRow.hasOwnProperty(key)) {
        missingKeys.push(key);
      }
    });

    const flexColumns = Object.keys(tagRows[0])
      .filter((columnName) => !(Object.values(TagKeys) as string[]).includes(columnName))
      .filter((item) => item);

    if (flexColumns.length > 4) {
      reject(new CustomError("tooMany", textTransform(flexColumns.join(", "), TagKeys, CSVHeaders)));
    }

    if (missingKeys.length === 0) {
      resolve();
    } else {
      const missingKeysString = missingKeys.join(", ");
      const errorMessage = textTransform(missingKeysString, TagKeys, CSVHeaders);
      reject(new Error(errorMessage));
    }
  });
});

export const createProject = createAsyncAction<ApiV1ProjectsPostRequest, Project, Error>(
  "CREATE_PROJECT",
  async (newProjectRequest, dispatch) => {
    const { data: newProject } = await call(ProjectsApi).apiV1ProjectsPost(newProjectRequest);
    await dispatch(getProjects());
    return newProject!;
  }
);

export const assignTagToTechs = createAsyncAction<ApiV1TagsIdTechnicianPostRequest, Tag, Error>(
  "ASSIGN_TAG_TO_TECHS",
  async (request) => {
    const { data } = await call(TagsApi).apiV1TagsIdTechnicianPost(request);
    if (!data) throw new Error();
    return data;
  }
);

export const assignAreaToTechs = createAsyncAction<ApiV1ProjectsIdAreasTechnicianPostRequest, SafeArea>(
  "ASSIGN_AREA_TO_TECHS",
  async (request) => {
    const { data } = await call(ProjectsApi).apiV1ProjectsIdAreasTechnicianPost(request);
    if (!data) throw new Error();
    return formatArea(data);
  }
);

export const getKpiById = createAsyncAction<
  ApiV1ProjectsIdKpiGetRequest,
  TagCountsByAreaAndPhaseListControllerResponse,
  Error
>("GET_KPI_BY_ID", (request) => {
  return call(ProjectsApi).apiV1ProjectsIdKpiGet(request);
});

export const closeProject = createAsyncAction<ApiV1ProjectsIdPutRequest, ApiV1ProjectsIdPutRequest, Error>(
  "CLOSE_PROJECT",
  async (request) => {
    await call(ProjectsApi).apiV1ProjectsIdPut({
      ...request,
      updateProjectRequest: { ...request.updateProjectRequest, isActive: false, isDeleted: false },
    });
    return request;
  }
);

export const deleteProject = createAsyncAction<ApiV1ProjectsIdPutRequest, ApiV1ProjectsIdPutRequest, Error>(
  "DELETE_PROJECT",
  async (request) => {
    await call(ProjectsApi).apiV1ProjectsIdPut({
      ...request,
      updateProjectRequest: { ...request.updateProjectRequest, isActive: false, isDeleted: true },
    });
    return request;
  }
);

export const createPhase = createAsyncAction<ApiV1ProjectsIdPhasesPostRequest[], PhaseControllerResponse[], Error>(
  "CREATE_PHASE",
  async (request) => {
    const results: PhaseControllerResponse[] = [];
    for (let i = 0; i < request.length; i++) {
      const phase = request[i];
      const result = await call(ProjectsApi).apiV1ProjectsIdPhasesPost(phase);
      results.push(result);
    }
    return results;
  }
);

export const updatePhaseName = createAsyncAction<ApiV1ProjectsIdPhasePutRequest, Phase, Error>(
  "UPDATE_PHASE_NAME",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdPhasePut(request);
    if (!data) throw new Error(error?.message || "");
    return data;
  }
);

export const createChecklist = createAsyncAction<ApiV1ProjectsIdChecklistsPostRequest, Checklist, Error>(
  "CREATE_CHECKLIST",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdChecklistsPost(request);
    if (!data) throw new Error(error?.message || "");

    return data || [];
  }
);

export const updateChecklist = createAsyncAction<ApiV1ProjectsIdChecklistsChecklistIdPutRequest, Checklist, Error>(
  "UPDATE_CHECKLIST",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdChecklistsChecklistIdPut(request);
    if (!data) throw new Error(error?.message || "");

    return data || [];
  }
);

export const deleteChecklist = createAsyncAction<ApiV1ProjectsIdChecklistsChecklistIdDeleteRequest, boolean, Error>(
  "DELETE_CHECKLIST",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdChecklistsChecklistIdDelete(request);
    if (!data) throw new Error(error?.message || "");

    return data || [];
  }
);

export const deleteAreaAttachment = createAsyncAction<ApiV1ProjectsAreaAttachmentsDeleteRequest, boolean, Error>(
  "DELETE_AREA_ATTACHMENT",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdAreasAttachmentIdDelete(request);
    if (!data) throw new Error(error?.message || "");

    return data || [];
  }
);

export const deleteProjectAttachment = createAsyncAction<ApiV1ProjectsAttachmentsDeleteRequest, boolean, Error>(
  "DELETE_PROJECT_ATTACHMENT",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdAttachmentIdDelete(request);
    if (!data) throw new Error(error?.message || "");

    return data || [];
  }
);

export const updateProjectIssue = createAsyncAction<ApiV1ProjectsIdIssuesIssueIdPutRequest, TagIssue, Error>(
  "UPDATE_PROJECT_ISSUE",
  async (request) => {
    const { data, error } = await call(ProjectsApi).apiV1ProjectsIdIssuesIssueIdPut(request);
    if (!data) throw new Error(error?.message || "");
    return data || {};
  }
);

export const getTagById = createAsyncAction<ApiV1ProjectsIdTagsTagIdGetRequest, TagCompletionDetail, Error>(
  "GET_TAG_BY_ID",
  async (request) => {
    const { data: tagCompletion } = await call(ProjectsApi).apiV1ProjectsIdTagsTagIdGet(request);
    return tagCompletion!;
  }
);

export const getProjectHandoverLink = createAsyncAction<number, string, Error>(
  "GET_PROJECT_HANDOVER",
  async (projectId) => {
    const { data: link } = await call(ProjectsApi).apiV1ProjectsIdHandoverpackagelinkGet({ id: projectId });
    return link || "";
  }
);

export const getChecklistsAvailableForCopying = createAsyncAction<number, ChecklistToCopy[]>(
  "GET_CHECKLISTS_AVAILABLE_FOR_COPYING",
  async (projectId) => {
    const { data: checklists } = await call(ProjectsApi).apiV1ProjectsIdChecklistsToCopyGet({ id: projectId });
    return checklists || [];
  }
);

export const copyChecklist = createAsyncAction<ApiV1ProjectsIdCopyChecklistPostRequest, Checklist>(
  "COPY_CHECKLIST_INTO_PROJECT",
  async (request) => {
    const { data: checklist, error } = await call(ProjectsApi).apiV1ProjectsIdCopyChecklistPost(request);
    if (!checklist) throw new Error(error?.message || "");
    return checklist;
  }
);

export const generateChecklistReport = createAsyncAction<void, void>("GENERATE_CHECKLIST_REPORT", async () => {
  await new Promise((resolve) => setTimeout(resolve, 300));
  return;
});

export const rejectChecklist = createAsyncAction<ApiV1ProjectsIdRejectChecklistPostRequest, boolean>(
  "REJECT_CHECKLIST",
  async (request) => {
    const { data: checklist, error } = await call(ProjectsApi).apiV1ProjectsIdRejectChecklistPost(request);
    if (!checklist) throw new Error(error?.message || "");
    return checklist instanceof Array;
  }
);

export const createOrUpdateExclusionList = createAsyncAction<ApiV1ProjectsIdExclusionListPutRequest, ExclusionList>(
  "CREATE_EXCLUSION_LIST",
  async (request, dispatch) => {
    const { data: exclusionList, error } = await call(ProjectsApi).apiV1ProjectsIdExclusionListPut(request);
    if (!exclusionList) throw new Error(error?.message || "");
    dispatch(getKpiById({ id: request.id }));
    return exclusionList;
  }
);

export const updateProjectTag = createAsyncAction<ApiV1ProjectsIdTagsPutRequest, Tag>(
  "UPDATE_PROJECT_TAG",
  async (request) => {
    const { data: tag, error } = await call(ProjectsApi).apiV1ProjectsIdTagsPut(request);
    if (!tag) throw new Error(error?.message || "");
    return tag;
  }
);
export const generateCompletedChecklistPackage = createAsyncAction<
  ApiV1ProjectsIdGenerateCompletedChecklistPackagePostRequest,
  boolean
>("GENERATE_COMPLETED_CHECKLIST_PACKAGE", async (request) => {
  const { data: tag, error } = await call(ProjectsApi).apiV1ProjectsIdGenerateCompletedChecklistPackagePost(request);
  if (!tag) throw new Error(error?.message || "");
  return true;
});
export const completedChecklistPackageLink = createAsyncAction<
  ApiV1ProjectsIdCompletedchecklistpackagelinkGetRequest,
  string
>("COMPLETED_CHECKLIST_PACKAGE_LINK", async (request) => {
  const { data: link, error } = await call(ProjectsApi).apiV1ProjectsIdCompletedchecklistpackagelinkGet(request);
  if (!link) throw new Error(error?.message || "");
  fileSaver.saveAs(link);

  return link;
});

export const getFlexFieldById = createAsyncAction<
  ApiV1ProjectsIdGetFlexFieldsGetRequest,
  FlexFieldIEnumerableControllerResponse,
  Error
>("GET_FLEX_FIELDS_BY_ID", async (request) => {
  const flexFields = await call(ProjectsApi).apiV1ProjectsIdGetFlexFieldsGet({ id: request.id });
  return flexFields!;
});

export const updateUpdateableFlexFields = createAsyncAction<
  ApiV1ProjectsIdPutRequest,
  ApiV1ProjectsIdPutRequest,
  Error
>("UPDATE_UPDATEABLE_FLEX_FIELDS", async (request, dispatch, getState) => {
  const state = getState();
  await call(ProjectsApi).apiV1ProjectsIdPut({
    ...request,
    updateProjectRequest: {
      ...state.admin.projects.project,
      ...request.updateProjectRequest,
      updateableFlexFields: request.updateProjectRequest?.updateableFlexFields,
    },
  });
  return request;
});

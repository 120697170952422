/* tslint:disable */
/* eslint-disable */
/**
 * EH Commissioning WebAPI 1.0
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface TagCountsByAreaAndPhase
 */
export interface TagCountsByAreaAndPhase {
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  areaId?: number;
  /**
   *
   * @type {string}
   * @memberof TagCountsByAreaAndPhase
   */
  areaName?: string | null;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  phaseId?: number;
  /**
   *
   * @type {string}
   * @memberof TagCountsByAreaAndPhase
   */
  phaseName?: string | null;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  completeTags?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  incompleteTags?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  notStartedTags?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  excludedTags?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  totalTags?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  criticalIssues?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  nonCriticalIssues?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  issuesTags?: number;
  /**
   *
   * @type {number}
   * @memberof TagCountsByAreaAndPhase
   */
  completeTagsWithoutIssues?: number;
}

export function TagCountsByAreaAndPhaseFromJSON(json: any): TagCountsByAreaAndPhase {
  return TagCountsByAreaAndPhaseFromJSONTyped(json, false);
}

export function TagCountsByAreaAndPhaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): TagCountsByAreaAndPhase {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    areaId: !exists(json, "areaId") ? undefined : json["areaId"],
    areaName: !exists(json, "areaName") ? undefined : json["areaName"],
    phaseId: !exists(json, "phaseId") ? undefined : json["phaseId"],
    phaseName: !exists(json, "phaseName") ? undefined : json["phaseName"],
    completeTags: !exists(json, "completeTags") ? undefined : json["completeTags"],
    incompleteTags: !exists(json, "incompleteTags") ? undefined : json["incompleteTags"],
    notStartedTags: !exists(json, "notStartedTags") ? undefined : json["notStartedTags"],
    excludedTags: !exists(json, "excludedTags") ? undefined : json["excludedTags"],
    totalTags: !exists(json, "totalTags") ? undefined : json["totalTags"],
    criticalIssues: !exists(json, "criticalIssues") ? undefined : json["criticalIssues"],
    nonCriticalIssues: !exists(json, "nonCriticalIssues") ? undefined : json["nonCriticalIssues"],
    issuesTags: !exists(json, "issuesTags") ? undefined : json["issuesTags"],
    completeTagsWithoutIssues: !exists(json, "completeTagsWithoutIssues")
      ? undefined
      : json["completeTagsWithoutIssues"],
  };
}

export function TagCountsByAreaAndPhaseToJSON(value?: TagCountsByAreaAndPhase | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    areaId: value.areaId,
    areaName: value.areaName,
    phaseId: value.phaseId,
    phaseName: value.phaseName,
    completeTags: value.completeTags,
    incompleteTags: value.incompleteTags,
    notStartedTags: value.notStartedTags,
    excludedTags: value.excludedTags,
    criticalIssues: value.criticalIssues,
    nonCriticalIssues: value.nonCriticalIssues,
    totalTags: value.totalTags,
    issuesTags: value.issuesTags,
    completeTagsWithoutIssues: value.completeTagsWithoutIssues,
  };
}
